import React, { useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import _ from "lodash";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { css } from "aphrodite/no-important";

import { auth } from "../../firebase.js";
import Logo from "../../components/Logo.js";
import Message from "../../components/Message.js";
import RandomImage from "../../components/RandomImage.js";
import Button from "../../components/Button.js";
import { styles as AuthStyles } from "../../components/Auth.css.js";
import { styles as ButtonStyles } from "../../components/Button.css.js";

function Signup() {
  const [pending, setPending] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [error, setError] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false); // New state to track checkbox

  const location = useLocation();

  // If authenticated, redirect the user to its original destination.
  if (auth.currentUser) {
    return <Navigate replace to={_.get(location.state, "from", "/")} />;
  }

  async function onSubmit(event) {
    event.preventDefault();
    setError("");

    // Ensure passwords match
    if (password !== passwordCheck) {
      setError("De wachtwoorden komen niet overeen.");
      return;
    }

    // Ensure user has agreed to the terms
    if (!agreedToTerms) {
      setError("U moet akkoord gaan met de voorwaarden om te kunnen registreren.");
      return;
    }

    setPending(true);

    try {
      const resp = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(resp.user);
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        setError(
          "Dit emailadres bestaat al in ons systeem. Indien u uw wachtwoord bent vergeten kunt u deze opnieuw opvragen op de login pagina.",
        );
      } else if (err.code === "auth/weak-password") {
        setError("Het opgegeven wachtwoord is te simpel.");
      } else if (err.code === "auth/invalid-email") {
        setError("Het emailadres moet nog ingevuld worden of is niet correct ingevuld.");
      } else {
        setError("Het lukt niet om u registreren, probeer het later nog eens.");
      }
    }

    setPending(false);
  }

  return (
    <div className={css(AuthStyles.Auth)}>
      <RandomImage />
      <div className={css(AuthStyles.Auth__right)}>
        <div>
          <div className={css(AuthStyles.Auth__logo)}>
            <Logo />
          </div>
          <form onSubmit={onSubmit} className={css(AuthStyles.Auth__form)}>
            <h4 className={css(AuthStyles.Auth__subtitle)}>Op het mooiste platform van Nederland staan? Doe mee!</h4>
            <p>
              <img alt="loading page" src="/static/top-nl.png" width={480} />
            </p>
            <ul className={css(AuthStyles.Auth__list)}>
              <li>Wij zijn één van de grootste spelers op de markt met het mooiste aanbod</li>
              <li>
                Op Feestjegeven.nl is adverteren <strong>GRATIS</strong>
              </li>
              <li>Aanvragen worden direct doorgestuurd, zonder tussenkomst</li>
              <li>Bij ons staat gebruikservaring voorop</li>
            </ul>
            <h4 className={css(AuthStyles["Auth__subtitle--is-hightlighted"])}>Premium lidmaatschap (vanaf €27,50- per maand)</h4>
            <p className={css(AuthStyles.Auth__paragraph)}>
              <ul className={css(AuthStyles.Auth__list)}>
                <li>Bij onze directe offerte aanvragen krijgt u voorkeur</li>
                <li>Uw vermelding valt veel meer op</li>
                <li>Uw website direct te bezoeken</li>
                <li>Uw telefoonnummer direct beschikbaar</li>
                <li>Uw vermelding wordt extra gepromoot via onze kanalen</li>
                <li>Onbeperkt aantal foto's toe te voegen</li>
              </ul>
            </p>

            <h4 className={css(AuthStyles.Auth__subtitle)}>Start nu met adverteren!</h4>
            <p className={css(AuthStyles.Auth__paragraph)}>Uw locatie staat dan binnen enkele uren online.</p>
            {!!error && <Message text={error} />}
            <div className={css(AuthStyles.Auth__row)}>
              <input
                type="text"
                name="email"
                required
                value={email}
                placeholder="Kies emailadres"
                onChange={(e) => setEmail(e.target.value)}
                className={css(AuthStyles.Auth__textfield)}
              />
              <input
                name="password"
                type="password"
                className={css(AuthStyles.Auth__textfield)}
                minLength="8"
                placeholder="Kies een wachtwoord"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                name="password-check"
                type="password"
                className={css(AuthStyles.Auth__textfield)}
                minLength="8"
                placeholder="Wachtwoord herhalen"
                value={passwordCheck}
                onChange={(e) => setPasswordCheck(e.target.value)}
              />
              <label htmlFor="user-agreement" className={css(AuthStyles.Auth__label_checkbox)}>
                <input
                  id="user-agreement"
                  type="checkbox"
                  checked={agreedToTerms}
                  onChange={(e) => setAgreedToTerms(e.target.checked)} // Update checkbox state
                />
                &nbsp; Ik ga akkoord met de{" "}
                <a href="/static/GEBRUIKSOVEREENKOMST_FG.pdf" target="blank">
                  voorwaarden
                </a>{" "}
                van Feestjegeven.nl
              </label>
            </div>
            <Button type="submit" label="Registreren" onClick={onSubmit} pending={pending} loadingLabel="Een momentje.." />
            <Link to="/login" className={css(ButtonStyles["Button__ghost"])}>
              Annuleren
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
